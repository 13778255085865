<template>
  <div>
    <div class="card card-custom">
      <div class="card-body">
        <div class="row m-0">
          <div class="col  mr-7 mb-7">
            <div class="bg-light-warning px-6 py-8 rounded-xl">
              <p class="text-warning font-weight-bold font-size-h6 text-center">{{$t('statistics.total_number_of_clients')}}</p>
              <h4 class="text-warning font-weight-bold font-size-h6 text-center">{{total_number_of_clients}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-primary px-6 py-8 rounded-xl">
              <p class="text-primary font-weight-bold font-size-h6 text-center">{{$t('statistics.the_number_of_customers_on_the_free_plan')}}</p>
              <h4 class="text-primary font-weight-bold font-size-h6 text-center">{{the_number_of_customers_on_the_free_plan}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-info px-6 py-8 rounded-xl">
              <p class="text-info font-weight-bold font-size-h6 text-center">{{$t('statistics.The_number_of_clients_on_the_first_plan')}}</p>
              <h4 class="text-info font-weight-bold font-size-h6 text-center">{{The_number_of_clients_on_the_first_plan}}</h4>
            </div>
          </div>

        </div>
        <div class="row m-0">
          <div class="col mr-7 mb-7">
            <div class="bg-light-danger px-6 py-8 rounded-xl">
              <p class="text-danger font-weight-bold font-size-h6 text-center">{{$t('statistics.the_number_of_customers_on_the_second_plan')}}</p>
              <h4 class="text-danger font-weight-bold font-size-h6 text-center">{{the_number_of_customers_on_the_second_plan}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-dark px-6 py-8 rounded-xl">
              <p class="text-dark font-weight-bold font-size-h6 text-center">{{$t('statistics.total_amounts_for_the_last_year')}}</p>
              <h4 class="text-dark font-weight-bold font-size-h6 text-center">{{total_amounts_for_the_last_year}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-success px-6 py-8 rounded-xl">
              <p class="text-success font-weight-bold font-size-h6 text-center">{{$t('statistics.total_amounts')}}</p>
              <h4 class="text-success font-weight-bold font-size-h6 text-center">{{total_amounts}}</h4>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col  mr-7 mb-7">
            <div class="bg-light-warning px-6 py-8 rounded-xl">
              <p class="text-warning font-weight-bold font-size-h6 text-center">{{$t('statistics.the_number_of_customers_suspended_due_to_payment')}}</p>
              <h4 class="text-warning font-weight-bold font-size-h6 text-center">{{the_number_of_customers_suspended_due_to_payment}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-primary px-6 py-8 rounded-xl">
              <p class="text-primary font-weight-bold font-size-h6 text-center">{{$t('statistics.number_of_plans')}}</p>
              <h4 class="text-primary font-weight-bold font-size-h6 text-center">{{number_of_plans}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-info px-6 py-8 rounded-xl">
              <p class="text-info font-weight-bold font-size-h6 text-center">{{$t('statistics.more_plan_number_of_users')}}</p>
              <h4 class="text-info font-weight-bold font-size-h6 text-center">{{more_plan_number_of_users}}</h4>
            </div>
          </div>

        </div>
        <div class="row m-0">
          <div class="col mr-7 mb-7">
            <div class="bg-light-danger px-6 py-8 rounded-xl">
              <p class="text-danger font-weight-bold font-size-h6 text-center">{{$t('statistics.minimum_number_of_users')}}</p>
              <h4 class="text-danger font-weight-bold font-size-h6 text-center">{{minimum_number_of_users}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-dark px-6 py-8 rounded-xl">
              <p class="text-dark font-weight-bold font-size-h6 text-center">{{$t('statistics.the_expected_monthly_amount_of_payment')}}</p>
              <h4 class="text-dark font-weight-bold font-size-h6 text-center">{{the_expected_monthly_amount_of_payment}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-success px-6 py-8 rounded-xl">
              <p class="text-success font-weight-bold font-size-h6 text-center">{{$t('statistics.the_number_of_accounts_that_pay_an_annual_subscription')}}</p>
              <h4 class="text-success font-weight-bold font-size-h6 text-center">{{the_number_of_accounts_that_pay_an_annual_subscription}}</h4>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col  mr-7 mb-7">
            <div class="bg-light-warning px-6 py-8 rounded-xl">
              <p class="text-warning font-weight-bold font-size-h6 text-center">{{$t('statistics.the_number_of_accounts_the_first_plan_a_monthly_subscription')}}</p>
              <h4 class="text-warning font-weight-bold font-size-h6 text-center">{{the_number_of_accounts_the_first_plan_a_monthly_subscription}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-primary px-6 py-8 rounded-xl">
              <p class="text-primary font-weight-bold font-size-h6 text-center">{{$t('statistics.the_number_of_accounts_is_a_second_plan_a_monthly_subscription')}}</p>
              <h4 class="text-primary font-weight-bold font-size-h6 text-center">{{the_number_of_accounts_is_a_second_plan_a_monthly_subscription}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-info px-6 py-8 rounded-xl">
              <p class="text-info font-weight-bold font-size-h6 text-center">{{$t('statistics.number_of_accounts_third_plan_monthly_subscription')}}</p>
              <h4 class="text-info font-weight-bold font-size-h6 text-center">{{number_of_accounts_third_plan_monthly_subscription}}</h4>
            </div>
          </div>

        </div>
        <div class="row m-0">
          <div class="col mr-7 mb-7">
            <div class="bg-light-danger px-6 py-8 rounded-xl">
              <p class="text-danger font-weight-bold font-size-h6 text-center">{{$t('statistics.the_number_of_accounts_is_the_first_annual_subscription_plan')}}</p>
              <h4 class="text-danger font-weight-bold font-size-h6 text-center">{{the_number_of_accounts_is_the_first_annual_subscription_plan}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-dark px-6 py-8 rounded-xl">
              <p class="text-dark font-weight-bold font-size-h6 text-center">{{$t('statistics.the_number_of_accounts_is_a_second_plan_an_annual_subscription')}}</p>
              <h4 class="text-dark font-weight-bold font-size-h6 text-center">{{the_number_of_accounts_is_a_second_plan_an_annual_subscription}}</h4>
            </div>
          </div>
          <div class="col mr-7 mb-7">
            <div class="bg-light-success px-6 py-8 rounded-xl">
              <p class="text-success font-weight-bold font-size-h6 text-center">{{$t('statistics.number_of_accounts_third_plan_annual_subscription')}}</p>
              <h4 class="text-success font-weight-bold font-size-h6 text-center">{{number_of_accounts_third_plan_annual_subscription}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!--end::Dashboard-->
</template>

<script>

import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "dashboard",
  data(){
    return{
      mainRoute: 'subscription/subscription/report_subscriptions',


      total_number_of_clients: null,
      the_number_of_customers_on_the_free_plan: null,
      The_number_of_clients_on_the_first_plan: null,
      the_number_of_customers_on_the_second_plan: null,
      total_amounts_for_the_last_year: null,
      total_amounts: null,
      the_number_of_customers_suspended_due_to_payment: null,
      number_of_plans: null,
      more_plan_number_of_users: null,
      minimum_number_of_users: null,
      the_expected_monthly_amount_of_payment: null,
      the_number_of_accounts_that_pay_an_annual_subscription: null,
      the_number_of_accounts_the_first_plan_a_monthly_subscription: null,
      the_number_of_accounts_is_a_second_plan_a_monthly_subscription: null,
      number_of_accounts_third_plan_monthly_subscription: null,
      the_number_of_accounts_is_the_first_annual_subscription_plan: null,
      the_number_of_accounts_is_a_second_plan_an_annual_subscription: null,
      number_of_accounts_third_plan_annual_subscription: null,
    }
  },
  methods:{
    getData(){
      ApiService.query(this.mainRoute).then((response)=>{

        this.total_number_of_clients = response.data.data.total_customers;
        this.the_number_of_customers_on_the_free_plan = response.data.data.number_customers_free_plan;
        this.The_number_of_clients_on_the_first_plan = response.data.data.number_customers_first_plan;
        this.the_number_of_customers_on_the_second_plan = response.data.data.number_customers_second_plan;
        this.total_amounts_for_the_last_year = response.data.data.total_amounts_last_year;
        this.total_amounts = response.data.data.total_amounts;
        this.the_number_of_customers_suspended_due_to_payment = response.data.data.number_customers_stopped_due_to_payment;
        this.number_of_plans = response.data.data.number_of_plans;
        this.more_plan_number_of_users = response.data.data.max_number_plan_users;
        this.minimum_number_of_users = response.data.data.min_number_plan_users;
        this.the_expected_monthly_amount_of_payment = response.data.data.expected_monthly_amount_payment;
        this.the_number_of_accounts_that_pay_an_annual_subscription = response.data.data.number_accounts_paid_with_an_annual_subscription;
        this.the_number_of_accounts_the_first_plan_a_monthly_subscription = response.data.data.number_accounts_first_plan_monthly_subscription;
        this.the_number_of_accounts_is_a_second_plan_a_monthly_subscription = response.data.data.number_accounts_second_plan_monthly_subscription;
        this.number_of_accounts_third_plan_monthly_subscription = response.data.data.number_accounts_third_plan_monthly_subscription;
        this.the_number_of_accounts_is_the_first_annual_subscription_plan = response.data.data.number_accounts_first_plan_annual_subscription;
        this.the_number_of_accounts_is_a_second_plan_an_annual_subscription = response.data.data.number_accounts_second_plan_annual_subscription;
        this.number_of_accounts_third_plan_annual_subscription = response.data.data.number_accounts_third_plan_annual_subscription;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.DASHBOARD")}]);
    this.getData();
  },
};
</script>
